import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  Breadcrumbs,
  TextBox,
  BlogSlider,
  BannerLinks
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import { generateBreadcrumbsLinks } from '../../utils/index'

const Transitions = () => {
  const links = generateBreadcrumbsLinks('/blog/3d-transitions', '3D Transitions')
  return (
    <Layout>
      <Helmet
        title="3D Transitions"
        meta={[
          {
            name: 'description',
            content:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.'
          },
          {
            name: 'keywords',
            content:
              '3D Transitions holzweg, Blog holzweg, Innsbruck, holzweg, Blog, 3D Transitions'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Lorem ipsum
            <br />
            dolores sed
          </span>
        }
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="3D Transitions"
        text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut."
      />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Transitions
